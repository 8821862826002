<template>
  <div class="addUser">
    <Loading :active.sync="visible"></Loading>
    <div class="content">
      <div class="firstName">
        <h1>First Name*</h1>
        <input type="text" v-model="firstName" placeholder="Name" />
      </div>
      <div class="lastName">
        <h1>Last Name*</h1>
        <input type="text" v-model="lastName" placeholder="Name" />
      </div>
      <div class="hubID">
        <h1>Hub ID*</h1>
        <input type="text" v-model="hubID" placeholder="Hub ID" />
      </div>
      <div class="email">
        <h1>Email*</h1>
        <input type="text" v-model="email" placeholder="Email" />
      </div>
      <div class="role">
        <h1>Role*</h1>
        <Multiselect v-model="role" :options="roleOptions" :show-labels="false" :allow-empty="false"></Multiselect>
        <p class="roleDescription">{{ roleDescription }}</p>
      </div>
      <div class="primary-btn" :class="{ disabled: !valid }" @click="addUser">Save New User</div>
    </div>
    <div class="hidden"></div>
    <!-- <footer class="footer">© 2020 Volkswagen Academy</footer> -->
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Loading from 'vue-loading-overlay';
import axios from 'axios';

export default {
  components: {
    Multiselect,
    Loading,
  },
  computed: {
    roleDescription() {
      if (this.role === 'Participant') {
        return 'Participants can view courses. They can also view all assets for their courses in the asset library. They cannot create courses or add new users.';
      }
      if (this.role === 'Instructor') {
        return 'Instructors can view courses to which they are assigned as instructors (based on their Hub ID). They can also view all assets for their courses in the asset library. They cannot create courses or add new users.';
      }
      if (this.role === 'Content Developer') {
        return 'Content developers have the ability to create new courses, assign instructors to courses, and view the contents of the asset library. They cannot add new users.';
      }
      if (this.role === 'Administrator') {
        return 'Admins have the ability to create new courses, assign instructors to courses, view the contents of the asset library, and add new users.';
      }
      return '';
    },
    ILTRoleID() {
      if (this.role === 'Participant') {
        return '1';
      }
      if (this.role === 'Instructor') {
        return '2';
      }
      if (this.role === 'Content Developer') {
        return '3';
      }
      if (this.role === 'Administrator') {
        return '4';
      }
      return '-1';
    },
    valid() {
      if (this.firstName && this.lastName && this.hubID && this.email && this.isEmailValid && this.ILTRoleID) {
        return true;
      }
      return false;
    },
    isEmailValid() {
      const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      return emailRegex.test(this.email);
    },
  },
  methods: {
    addUser() {
      this.visible = true;
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/users`,
          {
            firstName: this.firstName,
            lastName: this.lastName,
            hubID: this.hubID,
            email: this.email,
            ILTRoleID: this.ILTRoleID,
          },
          {
            headers: {
              auth_type: 'vw',
              authorization: sessionStorage.getItem('vwcup-token'),
            },
          },
        )
        .then(() => {
          this.visible = false;
          this.$router.push('/users');
        })
        .catch((error) => {
          console.error(error);
          this.visible = false;
        });
    },
  },
  watch: {
    role() {
      if (this.role === null) {
        this.role = 'Participant';
      }
    },
  },
  data() {
    return {
      visible: false,
      firstName: '',
      lastName: '',
      hubID: '',
      email: '',
      role: 'Participant',
      roleOptions: ['Participant', 'Instructor', 'Content Developer', 'Administrator'],
    };
  },
};
</script>

<style lang="scss" scoped>
.addUser {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 220px) !important;
  width: 80%;
  margin: 0 auto;
  min-height: calc(100vh - 220px) !important;
  @include lg {
    width: 100%;
    min-height: calc(100vh - 241px) !important;
  }
  &.fullWidth {
    padding: 0;
  }
  > .content {
    > div {
      // width: 80%;
      margin: 0 auto 15px auto;
      @include lg {
        width: 100%;
      }
    }
    h1 {
      font-family: $head-font-stack;
      font-weight: 700;
      font-size: 1rem;
      margin-bottom: 5px;
      margin-top: 0;
    }
    input {
      font-size: 1.4rem;
      height: 40px;
      width: 90%;
      margin: 0 auto;
      padding: 0 15px;
      color: #000 !important;
      outline: none;
      margin-top: 1px;
      font-weight: 300;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid $light-grey;
      border-radius: 3px;
      @include lg {
        width: 330px;
      }
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: #000;
        opacity: 0.5;
        font-weight: 300;
      }
    }
    .roleDescription {
      width: 100%;
      margin-bottom: 35px;
      font-weight: 300;
    }
    .multiselect {
      height: 40px;
      width: calc(90% + 30px);
      margin-bottom: 5px;
      @include lg {
        width: 360px;
      }
    }
    .primary-btn {
      // width: 172px;
      text-align: center;
      margin: 0 auto;
      @include md {
        width: 172px;
        margin: 0 auto 15px 0;
      }
      &.disabled {
        pointer-events: none;
        background: $light-grey;
        border-color: $light-grey;
        color: #707070;
      }
    }
  }
  .hidden {
    flex: auto;
  }
}
</style>
