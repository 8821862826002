<template>
  <div>
    <transition name="fade">
      <router-view></router-view>
    </transition>
    <footer v-if="$route.path === '/create-course' && $mq !== 'sm'" class="footer">© 2020 Volkswagen Academy</footer>
  </div>
</template>

<script>
export default {
  name: 'Create',
};
</script>

<style lang="scss" scoped>
.mobile {
  width: 62%;
  margin: 200px auto 50px auto;
  font-weight: 300;
  font-size: 2.8rem;
  text-align: center;
  > .primary-btn {
    @include md {
      width: 200px;
    }
    margin: 200px auto 0 auto;
  }
}

.fade-enter-active {
  animation: bounce-in 0.8s;
}
.fade-leave-active {
  animation: bounce-in 0.2s reverse;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
  }
  50% {
  }
  100% {
    opacity: 1;
  }
}
</style>
